import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import CreateSubmissionRequest from "@/types/CreateSubmissionRequest";
import QuerySubmissionsRequest from "@/types/pocos/QuerySubmissionsRequest";
import PagedUseCaseResponse from "@/types/pocos/PagedUseCaseResponse";
import Submission from "@/types/Entities/Submission";

export default class SubmissionApiService extends BaseApiService {
  async CreateSubmission(
    formName: string,
    instanceName: string | null,
    formData: object
  ) {
    await this.defineHeader();
    let url = `${this.url}/api/registration/Submission/${formName}`;
    if (instanceName) {
      url += `?instanceName=${instanceName}`;
    }

    return axios
      .post(url, formData)
      .then((resp) => resp.data as UseCaseResponse<string>)
      .catch((ex) => {
        const data = ex.response?.data;
        return {
          Value: ex,
          Success: false,
          Message: data?.Message,
          Errors: data?.Errors,
        };
      });
  }

  async CreateSubmissions(
    submissions: CreateSubmissionRequest[]
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/Submission`, submissions)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async GetSubmissionById(id: string): Promise<UseCaseResponse<Submission>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Submission/${id}`)
      .then((resp) => resp.data as UseCaseResponse<Submission>);
  }

  async QuerySubmissions(
    request: QuerySubmissionsRequest
  ): Promise<PagedUseCaseResponse<object>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/Submission/Query`, request)
      .then((resp) => resp.data as PagedUseCaseResponse<object>);
  }

  async UpdateSubmission(sub: Submission): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/registration/Submission/${sub.Id}`, sub)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }
}
