<template>
  <b-container fluid="xl">
    <div
      style="width: 500px; max-width: 100%; margin: 0 auto; margin-top: 40px"
    >
      <b-img
        fluid
        v-if="Locale === 'fr'"
        src="@/assets/livestem-tomatosphere/header-fr.png"
        alt="Le Tomatosphere"
        class="headerimg"
      />
      <b-img
        fluid
        v-else
        src="@/assets/livestem-tomatosphere/header-en.png"
        alt="Tomatosphere"
        class="headerimg"
      />
    </div>

    <h1 class="h2">{{ $t("LiveStemTomatosphere.Header") }}</h1>

    <div v-if="!submissionComplete">
      <p>
        {{ $t("LiveStemTomatosphere.Intro") }}
      </p>
      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <!-- broadcasts table -->
          <validation-provider
            v-slot="{ errors, valid }"
            name="broadcasts-vp"
            rules="required"
          >
            <table>
              <thead>
                <tr>
                  <th />
                  <th>{{ $t("LiveStemTomatosphere.Labels.Title") }}</th>
                  <th>{{ $t("LiveStemTomatosphere.Labels.Language") }}</th>
                  <th>{{ $t("LiveStemTomatosphere.Labels.Date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(b, idx) in Broadcasts" :key="idx">
                  <td class="select_col">
                    <!-- @vue-ignore -->
                    <b-form-checkbox
                      :value="b.canvas_course_id"
                      v-model="model.Broadcasts"
                    />
                  </td>
                  <td>{{ b.title }}</td>
                  <td>{{ b.language }}</td>
                  <td>{{ b.date }}</td>
                </tr>
              </tbody>
            </table>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t("LiveStemTomatosphere.Validation.BroadcastRequired")
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <your-school class="mt-4" v-model="model.School" />

          <h2 class="h5">
            {{ $t("ClassesOrGroups.Label") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="classcollection"
            :rules="{ required: true, min: 1 }"
          >
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <number-of-adults v-model="model.NumAdults" />

          <project-context v-model="model.Context" />

          <language
            :label="$t('LiveStemTomatosphere.Questions.Language')"
            v-model="model.Language"
          />

          <learned-about-project v-model="model.LearnedAbout" />

          <feedback v-model="model.Feedback" />

          <mailing-list id="mailing_list" v-model="model.MailingList" />

          <canada-post-shipping-address v-model="model.ShippingAddress" />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-4"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("LiveStemTomatosphere.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("LiveStemTomatosphere.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p
        id="confirmationbody"
        v-html="$t('LiveStemTomatosphere.Confirmation.Body')"
      />
      <div class="mb-2" v-for="(b, idx) in Broadcasts" :key="idx">
        <a target="__blank" :href="b.url">
          {{ b.title }}
        </a>
      </div>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SchoolInfo from "@/components/school/SchoolInfo.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import OrganizationApiService from "@/services/OrganizationApiService";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import Language from "@/components/questions/Language.vue";
import MailingList from "@/components/questions/MailingList.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import CanadaPostShippingAddress from "@/components/CanadaPostShippingAddress.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import Feedback from "@/components/questions/Feedback.vue";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

/* example json config
{
  "broadcasts": [
    {
      "title": "whatever",
      "canvas_course_id": "1234",
      "language": "English",
      "date": "2024-01-01"
    },
    {
      "title": "le whatever",
      "canvas_course_id": "5678",
      "language": "French",
      "date": "2024-01-02"
    }
  ]
}
*/

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SchoolLookup,
    SchoolInfo,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    OrgRegionSelect,
    Language,
    MailingList,
    NumberOfAdults,
    CanadaPostShippingAddress,
    YourSchool,
    YesNoRadioOptions,
    Feedback,
  },
})
export default class LiveStemTomatosphere extends FormBase {
  name = "";
  model: any = {
    ShippingAddress: {},
    School: {},
    Broadcasts: [],
  };

  orgApiService: OrganizationApiService = new OrganizationApiService();

  get Form(): string {
    return "LiveStemTomatosphere";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get Broadcasts(): any[] {
    if (!this.Metadata.broadcasts) return [];
    return this.Metadata.broadcasts;
  }

  mounted() {
    this.GetFormData().then(() => {
      if (!this.Editing) {
        this.model.season = "ts_live_stem";
      }
    });
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

hr {
  background-color: rgb(145, 145, 145);
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}

table {
  width: 100%;
}

th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

.select_col {
  width: 25px;
}
</style>
