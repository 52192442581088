<template>
  <div>
    <div v-for="(gItem, gIdx) in grades" :key="gIdx">
      <div class="form-row">
        <div class="col-lg mr-2 required-field">
          <label :for="`grade-${gIdx}`">{{
            $t("GradeCollection.Grade")
          }}</label>
          <b-select
            placeholder="Grade"
            :id="`grade-${gIdx}`"
            :options="gradeOptions"
            v-model="grades[gIdx].Grade"
            @input="onInput"
            size="sm"
            ref="GradeInput"
            :state="grades[gIdx].Grade !== null"
          >
            <template #first>
              <b-select-option :value="null" disabled
                >-- {{ $t("GradeCollection.Grade") }} --</b-select-option
              >
            </template>
          </b-select>
          <b-form-invalid-feedback :state="grades[gIdx].Grade !== null">
            {{ $t("Validation.SelectValidGrade") }}
          </b-form-invalid-feedback>
        </div>
        <div class="col-lg mr-2 required-field">
          <label :for="`numstudents-${gIdx}`">{{
            $t("GradeCollection.NumberOfStudents")
          }}</label>
          <b-form-input
            :id="`numstudents-${gIdx}`"
            type="number"
            v-model="grades[gIdx].NumStudents"
            @input="onInput"
            size="sm"
            :placeholder="$t('GradeCollection.NumberOfStudents')"
            number
            ref="NumStudentInput"
            :state="
              !isNaN(grades[gIdx].NumStudents) && grades[gIdx].NumStudents > 0
            "
          />
          <b-form-invalid-feedback
            :state="
              !isNaN(grades[gIdx].NumStudents) && grades[gIdx].NumStudents > 0
            "
          >
            {{ $t("Validation.ProvideNumericGreaterThanZero") }}
          </b-form-invalid-feedback>
        </div>
        <div class="col d-flex">
          <b-button
            v-if="gIdx > 0"
            variant="danger"
            id="removegradebtn"
            @click="onRemoveGradeClicked(gIdx)"
            class="align-self-center"
          >
            {{ $t("GradeCollection.RemoveGrade") }}
          </b-button>
        </div>
      </div>
    </div>
    <b-button
      id="addgradebtn"
      variant="outline-primary"
      @click="onAddGradeClicked"
    >
      {{ $t("GradeCollection.AddGrade") }}
    </b-button>
  </div>
</template>

<script lang="ts">
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrgEnumResponse from "@/types/GetOrgEnumResponse";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class GradeCollection extends Vue {
  @Prop(String) id!: string;
  @Prop(Array) value!: any[];
  @Prop(Boolean) descriptionValues: boolean | undefined;

  grades: any[] = [];
  gradeOptions: any[] = [];
  gradeModel: any = {};
  numStudentsValid: boolean | null = null;

  orgService: OrganizationApiService = new OrganizationApiService();

  @Watch("value")
  onValueChanged() {
    this.grades = this.value || [];
  }

  mounted() {
    this.onValueChanged();

    if (this.grades.length === 0) {
      this.grades = [
        {
          Grade: null,
          NumStudents: null,
        },
      ];
    }

    this.getData();
  }

  getData() {
    const locale: string = this.$i18n.locale === "fr" ? "fr-CA" : "en-CA";
    this.orgService.GetEnumerationList("LTSAgeGroups", locale).then((resp) => {
      this.gradeOptions = [];
      resp.Value.forEach((el: GetOrgEnumResponse) => {
        this.gradeOptions.push({
          text: el.Text,
          value: this.descriptionValues === true ? el.Description : el.Id,
        });
      });
    });
  }

  onAddGradeClicked() {
    this.grades.push({
      Grade: null,
      NumStudents: null,
    });
    this.onInput();
  }

  onRemoveGradeClicked(gIdx: number) {
    this.grades.splice(gIdx, 1);
    this.onInput();
  }

  onInput() {
    this.$emit("input", this.grades);
  }

  validateInputs(): boolean {
    let inputs: any = this.$refs.NumStudentInput;
    inputs = inputs.concat(this.$refs.GradeInput);

    let ret = true;
    inputs.forEach((i: any) => {
      if (i.state === false) {
        ret = false;
      }
    });

    return ret;
  }

  validate(): boolean {
    return this.validateInputs();
  }
}
</script>
<style scoped>
#removegradebtn {
  margin-bottom: 3px;
}

#addgradebtn {
  margin-bottom: 3px;
  margin-top: 10px;
}

ul.dropdown-menu.show {
  width: 100%;
}

.thick-rounded {
  border-width: 3px !important;
  border-radius: 5px !important;
}
</style>
