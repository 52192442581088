<template>
  <div :id="id">
    <p
      class="question required-field"
      :id="`${id}-q_comp_learnedAboutQuestion`"
    >
      <template v-if="!label">
        {{ $t("LearnedAboutProjectField.Question") }}
      </template>
      <template v-else>
        {{ label }}
      </template>
    </p>

    <validation-provider
      v-slot="{ errors, valid }"
      name="vp_comp_learnedabout"
      :rules="{ required: true }"
    >
      <b-form-group class="two-col-checkboxes">
        <b-form-checkbox-group
          :id="`${id}-cb-group`"
          v-model="learnedAbout"
          :options="learnedAboutOptions"
          aria-describedby="q_comp_learnedAboutQuestion"
        ></b-form-checkbox-group>
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class LearnedAboutProject extends Vue {
  @Prop(Array) value!: Array<string>;
  @Prop(String) id!: string;
  @Prop(String) label!: string;
  learnedAbout = this.value || [];

  @Watch("value")
  onValueChanged() {
    this.learnedAbout = this.value;
  }

  @Watch("learnedAbout")
  onContextChanged() {
    this.$emit("input", this.learnedAbout);
  }

  mounted() {
    this.onValueChanged();
  }

  get learnedAboutOptions() {
    return [
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Facebook"),
        value: "Facebook",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Instagram"),
        value: "Instagram",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Twitter"),
        value: "Twitter",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Google"),
        value: "Google Search",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Email"),
        value: "Direct email",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Volunteer"),
        value: "Outreach volunteer",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Teacher"),
        value: "Colleague",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Principal"),
        value: "Principal",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.SchoolBoard"),
        value: "Schoolboard",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Student"),
        value: "Student's family member",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Conference"),
        value: "Conference",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Ad"),
        value: "Printed ad",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.LinkedIn"),
        value: "LinkedIn",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Newsletter"),
        value: "Newsletter",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.LTSWebsite"),
        value: "LTSWebsite",
      },
      {
        text: this.$root.$t("LearnedAboutProjectField.Options.Other"),
        value: "Other",
      },
    ];
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
