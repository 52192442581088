<template>
  <b-container fluid="xl">
    <div style="width: 500px; max-width: 100%; margin: 50px auto">
      <b-img
        fluid
        v-if="$i18n.locale === 'fr'"
        src="@/assets/living-space/fr-header.png"
        :alt="$t('LivingSpace.BannerAlt')"
      />
      <b-img
        v-else
        fluid
        src="@/assets/living-space/en-header.png"
        :alt="$t('LivingSpace.BannerAlt')"
      />
    </div>

    <h1 class="h2">{{ $t("LivingSpace.DataSubmission.Header") }}</h1>

    <div v-if="!submissionComplete">
      <p>
        {{ $t("LivingSpace.DataSubmission.Intro.Text") }}
        <a href="#" @click="onDownloadDataset">{{
          $t("LivingSpace.DataSubmission.Intro.Link")
        }}</a>
      </p>
      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-card
            v-for="(e, idx) in model.Entries"
            :key="idx"
            :title="`${$t('LivingSpace.DataSubmission.EntryHeader')} ${idx}`"
          >
            <b-form-row>
              <b-col sm="12" md="3">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="`date-${idx}`"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    :id="`date-group-${idx}`"
                    :aria-label="
                      $t('LivingSpace.DataSubmission.Fields.DateOfCollection')
                    "
                    :label="
                      $t('LivingSpace.DataSubmission.Fields.DateOfCollection')
                    "
                    :label-for="`date-in-${idx}`"
                  >
                    <c-date
                      :id="`date-in-${idx}`"
                      v-model="model.Entries[idx].Date"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="3">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="`period-${idx}`"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    :id="`period-group-${idx}`"
                    :aria-label="
                      $t(
                        'LivingSpace.DataSubmission.Fields.DataCollectionPeriod'
                      )
                    "
                    :label="
                      $t(
                        'LivingSpace.DataSubmission.Fields.DataCollectionPeriod'
                      )
                    "
                    :label-for="`period-in-${idx}`"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          <img src="@/assets/icon/bs-clock.svg" />
                        </b-input-group-text>
                      </template>
                      <b-form-select
                        :id="`period-in-${idx}`"
                        v-model="model.Entries[idx].Period"
                        :options="periodOptions"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="12" md="3">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="`temp-${idx}`"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    :id="`temp-group-${idx}`"
                    :aria-label="
                      $t('LivingSpace.DataSubmission.Fields.ClassTemp')
                    "
                    :label="$t('LivingSpace.DataSubmission.Fields.ClassTemp')"
                    :label-for="`temp-in-${idx}`"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          <img src="@/assets/icon/bs-thermometer-half.svg" />
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        :id="`temp-in-${idx}`"
                        type="number"
                        placeholder="0 - 40"
                        v-model="model.Entries[idx].Temp"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="3">
                <b-form-group
                  :id="`humidity-group-${idx}`"
                  :aria-label="
                    $t('LivingSpace.DataSubmission.Fields.ClassHumidity')
                  "
                  :label="$t('LivingSpace.DataSubmission.Fields.ClassHumidity')"
                  :label-for="`humidity-in-${idx}`"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text>
                        <img src="@/assets/icon/bs-droplet-half.svg" />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      :id="`humidity-in-${idx}`"
                      type="number"
                      placeholder="0 - 100"
                      v-model="model.Entries[idx].Humidity"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="3">
                <b-form-group
                  :id="`co2-group-${idx}`"
                  :aria-label="$t('LivingSpace.DataSubmission.Fields.ClassCo2')"
                  :label-for="`co2-in-${idx}`"
                >
                  <template v-slot:label>
                    <label
                      style="margin: 0px; padding: 0px"
                      v-html="$t('LivingSpace.DataSubmission.Fields.ClassCo2')"
                    />
                  </template>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text> CO<sub>2</sub> </b-input-group-text>
                    </template>
                    <b-form-input
                      :id="`co2-in-${idx}`"
                      type="number"
                      placeholder="300 - 5000"
                      v-model="model.Entries[idx].CO2"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="12" md="3">
                <b-button
                  variant="danger"
                  :disabled="!model.Entries || model.Entries.length === 1"
                  @click="removeEntry(idx)"
                >
                  {{ $t("LivingSpace.DataSubmission.Buttons.Remove") }}
                </b-button>
              </b-col>
              <b-col
                sm="12"
                md="3"
                v-if="model.Entries && idx === model.Entries.length - 1"
              >
                <b-button variant="primary" @click="addEntry">
                  {{ $t("LivingSpace.DataSubmission.Buttons.Add") }}
                </b-button>
              </b-col>
            </b-form-row>
          </b-card>
          <b-button
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-card :title="$t('LivingSpace.DataSubmission.Confirmation.Header')">
        <b-card-text>
          {{ $t("LivingSpace.DataSubmission.Confirmation.Text") }}
        </b-card-text>
        <b-row align="center">
          <b-col>
            <b-button variant="primary" @click="onEnterNewData">{{
              $t("LivingSpace.DataSubmission.Confirmation.Buttons.EnterNewData")
            }}</b-button>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="onDownloadDataset">{{
              $t(
                "LivingSpace.DataSubmission.Confirmation.Buttons.DownloadDataset"
              )
            }}</b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              :href="$t('LivingSpace.Confirmation.ButtonLink')"
              >{{
                $t(
                  "LivingSpace.DataSubmission.Confirmation.Buttons.ReturnToProjectGuide"
                )
              }}</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import cDate from "@/components/inputs/cDate.vue";
import ReportApiService from "@/services/ReportApiService";
import FormBase from "@/types/FormBase";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    cDate,
  },
})
export default class LivingSpaceDataSubmissions extends FormBase {
  reportApiService: ReportApiService = new ReportApiService();
  model: any = {
    Entries: [],
  };

  get periodOptions(): Array<object> {
    return [
      {
        text: this.$root.$t("LivingSpace.DataSubmission.Options.Before11"),
        value: "Before 11 AM",
      },
      {
        text: this.$root.$t("LivingSpace.DataSubmission.Options.11to2"),
        value: "11AM to 2 PM",
      },
      {
        text: this.$root.$t("LivingSpace.DataSubmission.Options.After2"),
        value: "After 2 PM",
      },
    ];
  }

  get Form(): string {
    return "LivingSpaceData";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  mounted() {
    if (!this.model.Entries || this.model.Entries.length === 0) {
      this.model.Entries = [{}];
    }

    this.GetFormData();
  }

  onSubmit() {
    this.SaveSubmission(this.model);
  }

  addEntry() {
    const date = this.model.Entries[this.model.Entries.length - 1].Date;
    this.model.Entries.push({
      Date: date,
    });
  }

  removeEntry(idx: number) {
    if (this.model.Entries.length > 1) {
      this.model.Entries.splice(idx - 1, 1);
    }
  }

  onEnterNewData() {
    this.saving = false;
    this.model.Entries = [{}];
    this.submissionComplete = false;
  }

  onDownloadDataset() {
    const reportParams: any = {
      Locale: this.$i18n.locale,
    };
    if (this.Instance) reportParams.InstanceName = this.Instance;
    this.reportApiService
      .RunReport("LivingSpaceDataSubmissions", reportParams)
      .then((resp) => {
        if (!resp) {
          console.log(`failed to run report`);
        }
      });
  }
}
</script>
