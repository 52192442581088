<template>
  <b-container fluid="xl">
    <p>{{ $t("FormAdmin.Edit.Intro") }}</p>

    <validation-observer ref="form_vo">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col cols="4">
            <validation-provider
              v-slot="{ errors, valid }"
              name="name-vp"
              :rules="{
                required: true,
                name_unique: form_names,
              }"
            >
              <b-form-group
                id="name-group"
                :label="$t('FormAdmin.Edit.Name')"
                label-for="name"
              >
                <b-form-input id="name" type="text" v-model="model.Name" />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors, valid }"
              name="version-vp"
              :rules="{
                required: true,
                numeric: true,
                min_value: 1,
              }"
            >
              <b-form-group
                id="version-group"
                :label="$t('FormAdmin.Edit.Version')"
                label-for="version"
              >
                <b-form-input
                  id="version"
                  type="number"
                  v-model="model.Version"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              id="grantsrole-group"
              :label="$t('FormAdmin.Edit.GrantsRole')"
              label-for="grantsrole"
            >
              <b-form-input
                id="grantsrole"
                type="text"
                v-model="model.GrantsRole"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="integration-route-group"
              :label="$t('FormAdmin.Edit.IntegrationRouteName')"
              label-for="integration-route"
            >
              <b-form-input
                id="integration-route"
                type="text"
                v-model="model.IntegrationRouteName"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
              id="eventname-group"
              :label="$t('FormAdmin.Edit.EventName')"
              label-for="eventname"
            >
              <b-form-select
                id="eventname"
                :options="eventNameOptions"
                v-model="model.EventNameId"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="eventtype-group"
              :label="$t('FormAdmin.Edit.EventType')"
              label-for="eventtype"
            >
              <b-form-select
                id="eventtype"
                :options="eventTypeOptions"
                v-model="model.EventTypeId"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="program-group"
              :label="$t('FormAdmin.Edit.Program')"
              label-for="program"
            >
              <b-form-select
                id="program"
                :options="programOptions"
                v-model="model.ProgramId"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-form-group
            id="direct-group"
            :label="$t('FormAdmin.Edit.Direct')"
            label-for="direct`"
          >
            <b-form-checkbox id="direct" v-model="model.IsDirect" />
          </b-form-group>
          <b-form-group
            class="ml-4"
            id="require-instance-group"
            :label="$t('FormAdmin.Edit.RequireInstance')"
            label-for="require-instance"
          >
            <b-form-checkbox
              id="require-instance"
              v-model="model.RequireInstance"
            />
          </b-form-group>
          <b-form-group
            class="ml-4"
            id="single-submission-group"
            :label="$t('FormAdmin.Edit.PreventMultiSubmission')"
            label-for="single-submission"
          >
            <b-form-checkbox
              id="multi-submission"
              v-model="model.PreventMultipleSubmissions"
            />
          </b-form-group>
        </b-form-row>
        <b-form-group
          id="metadata-group"
          :label="$t('FormAdmin.Edit.MetadataJson')"
          label-for="metadata"
        >
          <b-textarea
            id="metadata"
            v-model="metadata_json"
            style="width: 100%"
          />
        </b-form-group>
        <p class="text-muted" style="font-style: italic">
          {{ $t("FormAdmin.Edit.MetadataHint") }}
        </p>
      </b-form>
    </validation-observer>

    <hr />
    <h3>{{ $t("FormAdmin.Edit.Instances") }}</h3>
    <validation-observer v-slot="{ handleSubmit }" ref="instance_vo">
      <b-form @submit.prevent="handleSubmit(addInstance)">
        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors, valid }"
              name="instance-name-vp"
              :rules="{
                required: true,
                name_unique: instance_names,
              }"
            >
              <b-form-group
                id="instance-name-group"
                :label="$t('FormAdmin.Edit.Instance.Name')"
                label-for="instance-name"
              >
                <b-form-input
                  id="instance-name"
                  type="text"
                  v-model="instanceModel.Name"
                />
                <b-form-text>{{
                  $t("FormAdmin.Edit.Instance.NameHint")
                }}</b-form-text>
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors, valid }"
              name="instance-sd-vp"
              rules="required"
            >
              <b-form-group
                id="start-group"
                :label="$t('FormAdmin.Edit.Instance.StartDate')"
                label-for="start"
              >
                <b-form-input
                  id="start"
                  type="date"
                  v-model="instanceModel.StartDate"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col>
            <b-form-group
              id="end-group"
              :label="$t('FormAdmin.Edit.Instance.EndDate')"
              label-for="end"
            >
              <b-form-input
                id="end"
                type="date"
                v-model="instanceModel.EndDate"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              style="margin-top: 65px"
              type="submit"
              variant="primary"
              >{{ $t("FormAdmin.Edit.Instance.Add") }}</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-table
      responsive
      striped
      hover
      :fields="instanceFields"
      :items="instances"
    >
      <template #cell(Name)="instance">
        <b-input type="text" v-model="instance.item.Name" />
      </template>
      <template #cell(StartDate)="instance">
        <c-date v-model="instance.item.StartDate" />
      </template>
      <template #cell(EndDate)="instance">
        <c-date v-model="instance.item.EndDate" />
      </template>
      <template #cell(Actions)="instance">
        <b-icon-link
          class="h4"
          v-b-tooltip.hover
          :title="$t('FormAdmin.Edit.Instance.CopyUrl')"
          @click="copyInstanceUrlToClipboard(instance.item)"
        />
        <b-icon-trash
          class="h4 ml-2"
          v-b-tooltip.hover
          :title="$t('FormAdmin.Edit.Instance.Delete')"
          @click="deleteInstance(instance.item)"
        />
      </template>
    </b-table>

    <b-button
      variant="primary"
      class="mt-4"
      :disabled="loading"
      @click="save"
      >{{ $t("FormAdmin.Edit.Save") }}</b-button
    >
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FormApiService from "@/services/FormApiService";
import cDate from "@/components/inputs/cDate.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import EventNameApiService from "@/services/enumerations/EventNameApiService";
import EventTypeApiService from "@/services/enumerations/EventTypeApiService";
import ProgramApiService from "@/services/enumerations/ProgramApiService";
import { RouteRecordPublic } from "vue-router";

extend("name_unique", {
  message: "FormAdmin.Validation.UniqueFormName",
  validate: (value, names) => validateName(value, names),
});

function validateName(v: string, names: any): boolean {
  return (names as string[]).includes(v) === false;
}

@Component({
  components: {
    cDate,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormEdit extends Vue {
  loading = true;
  formApiService = new FormApiService();
  form_names: any[] = [];

  model: any = {
    FormInstances: [],
  };

  instanceModel: any = {};

  instanceFields: object[] = [
    {
      key: "Name",
      label: this.$root.$t("FormAdmin.Edit.Instance.Name"),
    },
    {
      key: "StartDate",
      label: this.$root.$t("FormAdmin.Edit.Instance.StartDate"),
    },
    {
      key: "EndDate",
      label: this.$root.$t("FormAdmin.Edit.Instance.EndDate"),
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  get metadata_json(): string {
    return this.model.Metadata ? JSON.stringify(this.model.Metadata) : "";
  }

  set metadata_json(v: string) {
    try {
      if (v) {
        this.model.Metadata = JSON.parse(v);
      } else {
        this.model.Metadata = null;
      }
    } catch {
      this.model.Metadata = null;
    }
  }

  get instances(): object[] {
    const ret = this.model.FormInstances || [];
    return (ret as any[]).filter((i) => !i.Deleted);
  }

  get instance_names(): string[] {
    return this.instances.map((i: any) => i.Name);
  }

  eventNameApiService: EventNameApiService = new EventNameApiService();
  eventNames: any[] = [];
  get eventNameOptions(): object[] {
    return this.eventNames.map((en) => {
      return {
        text: en.Name,
        value: en.Id,
      };
    });
  }

  eventTypeApiService: EventTypeApiService = new EventTypeApiService();
  eventTypes: any[] = [];
  get eventTypeOptions(): object[] {
    return this.eventTypes.map((et) => {
      return {
        text: et.Name,
        value: et.Id,
      };
    });
  }

  programApiService: ProgramApiService = new ProgramApiService();
  programs: any[] = [];
  get programOptions(): object[] {
    return this.programs.map((p) => {
      return {
        text: p.Name,
        value: p.Id,
      };
    });
  }

  mounted() {
    const promises: any[] = [
      this.eventNameApiService.GetAll(),
      this.eventTypeApiService.GetAll(),
      this.programApiService.GetAll(),
      this.formApiService.GetForms(),
    ];

    const f_id: string = this.$router.currentRoute.params.id;
    if (f_id) {
      promises.push(this.formApiService.GetFormById(f_id));
    }

    Promise.all(promises)
      .then((resps: any[]) => {
        this.eventNames = resps[0].Value;
        this.eventTypes = resps[1].Value;
        this.programs = resps[2].Value;

        if (resps.length === 5) {
          this.model = resps[4].Value;
        }

        this.form_names = resps[3].Value.filter(
          (f: any) => f.Id !== this.model.Id
        ).map((f: any) => f.Name);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  addInstance() {
    if (!this.model.FormInstances) {
      this.model.FormInstances = [];
    }

    this.model.FormInstances.push(this.instanceModel);
    this.instanceModel = {};
    (this.$refs.instance_vo as any).reset();
  }

  save() {
    (this.$refs.form_vo as any).validate().then((success: boolean) => {
      if (!success) return;

      this.loading = true;
      if (this.model.Id) {
        this.formApiService.UpdateForm(this.model).then((resp) => {
          if (resp.Success) {
            this.$router.go(-1);
          } else {
            console.log(resp);
          }
        });
      } else {
        this.formApiService.CreateForm(this.model).then((resp) => {
          if (resp.Success) {
            this.$router.go(-1);
          } else {
            console.log(resp);
          }
        });
      }
    });
  }

  getFormPath(): string {
    const routes: RouteRecordPublic[] = this.$router.getRoutes();
    const form_route = routes.find((r) => r.name === this.model.Name);
    const url = `${window.location.host}${form_route?.path.replace(
      ":instance?",
      ""
    )}`;

    return url;
  }

  copyInstanceUrlToClipboard(i: any) {
    const url = `${this.getFormPath()}${i.Name}`;
    this.copyToClipboard(url);
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  deleteInstance(i: any) {
    if (confirm("Delete form instance?")) {
      i.Deleted = true;
    }
  }
}
</script>
