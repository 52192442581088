<template>
  <div :id="id">
    <p class="mb-0 question">
      {{ Label }}
    </p>
    <validation-provider
      v-slot="{ errors, valid }"
      :name="`${id}-vp`"
      :rules="{ required: true, numeric: true, min_value: AllowZero }"
    >
      <b-form-group
        :id="`${id}-group`"
        :label="$t('NumberOfAdults')"
        class="required-field"
        label-for="adults"
      >
        <b-form-input
          :id="`${id}-select`"
          type="number"
          class="binput"
          v-model="numAdults"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class NumberOfAdults extends Vue {
  @Prop(Number) value!: number;
  @Prop(String) id!: string;
  @Prop(String) label!: string;
  @Prop(Boolean) allowZero!: boolean | undefined;

  numAdults: string | null = this.value?.toString() || null;

  get Label(): string {
    return this.label || this.$t("HowManyAdults").toString();
  }

  get AllowZero(): number {
    return this.allowZero ? 0 : 1;
  }

  get IntValue(): number | null {
    return this.numAdults ? parseInt(this.numAdults) : null;
  }

  @Watch("value")
  onValueChanged() {
    this.numAdults = this.value?.toString() || null;
  }

  mounted() {
    this.onValueChanged();
  }

  onInput() {
    this.$emit("input", this.IntValue);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
