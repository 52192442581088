<template>
  <b-container fluid="xl">
    <div style="width: 500px; max-width: 100%; margin: 50px auto">
      <b-img
        fluid
        v-if="$i18n.locale === 'fr'"
        src="@/assets/tomatosphere/header-fr.png"
        :alt="$t('Tomatosphere.DataSubmission.BannerAlt')"
      />
      <b-img
        v-else
        fluid
        src="@/assets/tomatosphere/header-en.png"
        :alt="$t('Tomatosphere.DataSubmission.BannerAlt')"
      />
    </div>

    <h1 class="h2">{{ $t("Tomatosphere.DataSubmission.Header") }}</h1>

    <div v-if="!submissionComplete">
      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <h2 class="h5">
            {{ $t("Tomatosphere.DataSubmission.Questions.SeedGroup") }}
          </h2>

          <validation-provider
            v-slot="{ errors, valid }"
            name="SeedGroup"
            :rules="{ required: true }"
          >
            <b-form-group
              id="seedgroup-group"
              :label="$t('Tomatosphere.DataSubmission.Labels.SeedGroup')"
              label-for="seed-group"
              class="required-field"
            >
              <b-form-select
                id="seed-group"
                class="binput"
                v-model="model.SeedGroup"
                :options="seedGroupOptions"
                :state="errors[0] ? false : valid ? true : null"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <your-school class="mt-4" v-model="model.School" />

          <template v-if="selectedSeedGroup.Id !== undefined">
            <div
              v-for="(cItem, cIdx) in model.Classes"
              :key="cIdx"
              class="mb-4 mt-4 border border-light rounded p-4 thick-rounded"
            >
              <span class="h5 light-blue"
                >{{ $t("Tomatosphere.DataSubmission.Class") }}
                {{ cIdx + 1 }}</span
              >

              <b-button
                v-if="cIdx > 0"
                :id="`removeclassbtn_${cIdx}`"
                variant="danger"
                class="fullwidth ml-4 removeclassbtn"
                @click="onRemoveClassClicked(cIdx)"
              >
                {{ $t("Tomatosphere.DataSubmission.RemoveClass") }}
              </b-button>
              <p class="mt-2" style="font-weight: bold">
                {{ $t("Tomatosphere.DataSubmission.Labels.SeedGroup") }}
                {{ selectedSeedGroup.TreatedLabel }}
              </p>
              <div class="form-row mt-0">
                <div class="col-lg">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="`${cIdx}-TreatedPlanted`"
                    :rules="{ required: true, numeric: true, min_value: 1 }"
                  >
                    <b-form-group
                      :id="`${cIdx}-tplanted`"
                      :label="$t('Tomatosphere.DataSubmission.NumberPlanted')"
                      class="required-field"
                      :label-for="`${cIdx}-treated-planted`"
                    >
                      <b-form-input
                        :id="`${cIdx}-treated-planted`"
                        type="number"
                        class="binput"
                        v-model.number="cItem.TreatedPlanted"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="valid">
                      <span v-for="(error, index) in errors" :key="index">{{
                        $t(error)
                      }}</span>
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-lg">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="`${cIdx}-TreatedGerminated`"
                    :rules="{
                      required: true,
                      numeric: true,
                      lt_planted: { planted: cItem.TreatedPlanted },
                    }"
                  >
                    <b-form-group
                      :id="`${cIdx}-tgerminated`"
                      :label="
                        $t('Tomatosphere.DataSubmission.NumberGerminated')
                      "
                      class="required-field"
                      :label-for="`${cIdx}-treated-germinated`"
                    >
                      <b-form-input
                        :id="`${cIdx}-treated-germinated`"
                        type="number"
                        class="binput"
                        v-model.number="cItem.TreatedGerminated"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="valid">
                      <span v-for="(error, index) in errors" :key="index">{{
                        $t(error)
                      }}</span>
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
              <hr />
              <p class="mt-2" style="font-weight: bold">
                {{ $t("Tomatosphere.DataSubmission.Labels.SeedGroup") }}
                {{ selectedSeedGroup.UntreatedLabel }}
              </p>
              <div class="form-row mt-0">
                <div class="col-lg">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="`${cIdx}-UntreatedPlanted`"
                    :rules="{ required: true, numeric: true, min_value: 1 }"
                  >
                    <b-form-group
                      :id="`${cIdx}-utplanted`"
                      :label="$t('Tomatosphere.DataSubmission.NumberPlanted')"
                      class="required-field"
                      :label-for="`${cIdx}-untreated-planted`"
                    >
                      <b-form-input
                        :id="`${cIdx}-untreated-planted`"
                        type="number"
                        class="binput"
                        v-model.number="cItem.UntreatedPlanted"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="valid">
                      <span v-for="(error, index) in errors" :key="index">{{
                        $t(error)
                      }}</span>
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-lg">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="`${cIdx}-UntreatedGerminated`"
                    :rules="{
                      required: true,
                      numeric: true,
                      lt_planted: { planted: cItem.UntreatedPlanted },
                    }"
                  >
                    <b-form-group
                      :id="`${cIdx}-utgerminated`"
                      :label="
                        $t('Tomatosphere.DataSubmission.NumberGerminated')
                      "
                      class="required-field"
                      :label-for="`${cIdx}-untreated-germinated`"
                    >
                      <b-form-input
                        :id="`${cIdx}-untreated-germinated`"
                        type="number"
                        class="binput"
                        v-model.number="cItem.UntreatedGerminated"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="valid">
                      <span v-for="(error, index) in errors" :key="index">{{
                        $t(error)
                      }}</span>
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>
            <b-button
              :id="`addclassbtn`"
              variant="primary"
              @click="onAddClassClicked"
            >
              {{ $t("Tomatosphere.DataSubmission.AddClass") }}
            </b-button>
          </template>
          <br />
          <b-button
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-card :title="$t('Tomatosphere.DataSubmission.Confirmation.Header')">
        <b-card-text>
          {{ $t("Tomatosphere.DataSubmission.Confirmation.Text") }}
        </b-card-text>
        <b-button
          variant="primary"
          :href="
            $t('Tomatosphere.DataSubmission.Confirmation.ViewResults.Link')
          "
        >
          {{
            $t("Tomatosphere.DataSubmission.Confirmation.ViewResults")
          }}</b-button
        >
      </b-card>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import TomatosphereApiService from "@/services/TomatosphereApiService";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

extend("lt_planted", {
  validate(value, { planted }: any) {
    if (value > planted)
      return "Tomatosphere.DataSubmission.Validation.GreaterThanPlanted";
    else return true;
  },
  params: ["planted"],
});

class TSClassData {
  TreatedPlanted = 0;
  UntreatedPlanted = 0;
  TreatedGerminated = 0;
  UntreatedGerminated = 0;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    YourSchool,
  },
})
export default class TomatosphereDataSubmission extends FormBase {
  model: any = {
    Classes: [new TSClassData()],
  };

  tsApiService: TomatosphereApiService = new TomatosphereApiService();

  seedGroups: any[] = [];

  get selectedSeedGroup(): any {
    return this.model.SeedGroup ?? {};
  }

  get seedGroupOptions(): object[] {
    return this.seedGroups.map((sg) => {
      return {
        text: `${sg.TreatedLabel} & ${sg.UntreatedLabel}`,
        value: sg,
      };
    });
  }

  get Form(): string {
    return "TomatosphereData";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  mounted() {
    this.GetFormData();
    this.tsApiService.GetSeedGroups().then((resp) => {
      if (resp.Success) {
        this.seedGroups = resp.Value.filter((sg: any) => sg.IsActive).sort(
          (a: any, b: any) => {
            //sort alphabetically
            return a.TreatedLabel.localeCompare(b.TreatedLabel);
          }
        );
      }
    });
  }

  onAddClassClicked() {
    this.model.Classes.push(new TSClassData());
  }

  onRemoveClassClicked(idx: number) {
    this.model.Classes.splice(idx, 1);
  }

  onSubmit() {
    this.SaveSubmission(this.model);
  }
}
</script>
