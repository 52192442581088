import { Vue } from "vue-property-decorator";
import SubmissionApiService from "@/services/SubmissionApiService";
import RouteDataApiService from "@/services/RouteDataApiService";
import AuthorizationService from "@/services/AuthorizationService";
import RouteData, { NewRouteData } from "./pocos/RouteData";
import Submission from "./Entities/Submission";

export default abstract class FormBase extends Vue {
  submissionApiService: SubmissionApiService = new SubmissionApiService();
  routeDataApiService: RouteDataApiService = new RouteDataApiService();
  authorizationService: AuthorizationService =
    AuthorizationService.getInstance();

  model: any = {};
  submission: Submission | null = null;

  get Editing(): boolean {
    return this.submission !== null;
  }

  get submissionComplete(): boolean {
    if (this.show_confirmation) {
      return true;
    } else {
      return this.submission_complete;
    }
  }

  set submissionComplete(value: boolean) {
    this.submission_complete = value;
  }

  private show_confirmation = false;
  private submission_complete = false;
  saving = false;

  RouteData: RouteData = NewRouteData();

  DataLoaded = false;

  //abstract
  abstract get Form(): string;
  abstract get Instance(): string | null;

  get Metadata(): any {
    return this.RouteData.FormMetadata ?? {};
  }

  async GetFormData(): Promise<object> {
    Vue.prototype.$SetFormLoading(true);

    const rd = await this.routeDataApiService.GetRouteData(
      this.Form,
      this.Instance
    );

    this.RouteData = rd.Value;
    this.DataLoaded = true;

    const id = this.$router.currentRoute.query.id as string;
    if (id) {
      const elevated = this.authorizationService.hasRole([
        "administrator",
        "staffadmin",
      ]);
      if (!elevated) {
        this.GoToFormClosed();
      } else {
        await this.loadSubmission(id);
      }
    } else {
      if (!this.RouteData.FormId || this.RouteData.InstanceActive === false) {
        //no valid form
        this.GoToFormClosed();
      } else if (!this.RouteData.InstanceId && this.RouteData.RequireInstance) {
        //missing required instance
        this.GoToFormClosed();
      }

      if (this.RouteData.PreventMultipleSubmissions) {
        //already submit
        this.submission_complete = this.RouteData.Submissions > 0;
      }
    }

    this.checkConfirmationParam();

    Vue.prototype.$SetFormLoading(false);

    return this.RouteData;
  }

  async loadSubmission(id: string) {
    await this.submissionApiService.GetSubmissionById(id).then((resp) => {
      if (resp.Value) {
        this.submission = resp.Value;
        this.model = JSON.parse(this.submission.RegistrationJson);
      }
    });
  }

  checkConfirmationParam() {
    if (
      this.authorizationService.hasRole(["staff"]) &&
      this.$route.query.showConfirmation === "true"
    ) {
      this.show_confirmation = true;
    }
  }

  GoToFormClosed() {
    this.$router.push("/FormClosed");
  }

  async SaveSubmission(model: object): Promise<string> {
    this.saving = true;
    let id = "";

    try {
      let success = false;
      let ucr;

      if (this.submission && this.submission.Id) {
        this.submission.RegistrationJson = JSON.stringify(model); //this.model);
        ucr = await this.submissionApiService.UpdateSubmission(this.submission);

        id = this.submission.Id;
        success = ucr.Success;
      } else {
        ucr = await this.submissionApiService.CreateSubmission(
          this.Form,
          this.Instance,
          model
        );

        id = ucr.Value;
        success = ucr.Success;
      }

      if (success) {
        this.SubmissionComplete();

        if (this.Editing) {
          this.$router.back();
        }
      } else {
        console.log(ucr);
      }
    } catch (err) {
      console.log("an exception was thrown saving submission: ");
      console.log(err);
    }

    this.saving = false;
    return id;
  }

  SubmissionComplete() {
    this.submissionComplete = true;
    window.dispatchEvent(new Event("ltscr_form_submit"));
  }
}
