<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h3>{{ $t("LearningPathways.Header") }}</h3>
      <p v-if="!HasOptionsFiltered">{{ $t("LearningPathways.Intro") }}</p>
      <template v-else>
        <p v-if="model.FilterOption === grades_option">
          {{ $t("LearningPathways.Intro.Grades") }}
        </p>
        <p v-if="model.FilterOption === pathways_option">
          {{ $t("LearningPathways.Intro.Pathways") }}
        </p>
      </template>

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider
            v-slot="{ errors, valid }"
            name="grades-vp"
            :rules="{ required: true }"
          >
            <b-form-group label-for="grades">
              <b-form-radio-group
                id="grades"
                v-model="model.CourseGroup"
                stacked
              >
                <div class="mt-4 mb-2" v-if="!HasOptionsFiltered">
                  {{ $t("LearningPathways.Grades") }}
                </div>
                <template
                  v-if="
                    !HasOptionsFiltered || model.FilterOption === grades_option
                  "
                >
                  <b-form-radio
                    v-for="(op, idx) in GradeOptions"
                    :key="idx"
                    :value="op.value"
                  >
                    {{ op.text }}
                  </b-form-radio>
                </template>
                <div class="mt-4 mb-2" v-if="!HasOptionsFiltered">
                  {{ $t("LearningPathways.Pathways") }}
                </div>
                <template
                  v-if="
                    !HasOptionsFiltered ||
                    model.FilterOption === pathways_option
                  "
                >
                  <b-form-radio
                    v-for="(op, idx) in PathwayOptions"
                    :key="`pw-${idx}`"
                    :value="op.value"
                  >
                    {{ op.text }}
                  </b-form-radio>
                </template>
              </b-form-radio-group>
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>

            <interstitial-data-collection
              v-if="!valid_interstitial_data"
              :form-name="Form"
              v-model="model.InterstitialData"
            />
          </validation-provider>

          <div style="margin-top: 10px">
            <b-button
              id="btn_submit"
              type="submit"
              variant="primary"
              class="mt-4"
              :disabled="saving"
              >{{ $t("Submit") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("LearningPathways.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("LearningPathways.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p
        id="confirmationbody"
        v-html="$t('LearningPathways.Confirmation.Body')"
      />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import GradeCollection from "@/components/GradeCollection.vue";
import OrganizationApiService from "@/services/OrganizationApiService";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import FormBase from "@/types/FormBase";
import YourSchool from "@/components/questions/YourSchool.vue";
import AuthorizationService from "@/services/AuthorizationService";
import SSOApiService from "@/services/SSOApiService";
import InterstitialDataCollection from "@/components/InterstitialDataCollection.vue";
import CreateSubmissionRequest from "@/types/CreateSubmissionRequest";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

/* metadata example
{
  "en": {
    "grades": [
      {
        "text": "Level 1 STEM Certified Educator K - 6"
      },
      {
        "text": "Level 1 STEM Certified Educator 7 - 12"
      }
    ],
    "pathways": [
      {
        "text": "Cross-Curricular Learning"
      },
      {
        "text": "STEM Focused Learning (K-6)"
      }
    ]
  },
  "fr": {
    "grades": [
      {
        "text": "Enseignant.e certifié.e des STIM - niveau 1 (M à 6e)"
      },
      {
        "text": "Enseignant.e certifié.e des STIM - niveau 1 (7e à 12e)"
      }
    ],
    "pathways": [
      {
        "text": "L'apprentissage interdisciplinaire en STIM"
      },
      {
        "text": "Axé sur les STIM (M à 6e)"
      }
    ]
  }
}
  */

enum FilterOption {
  None,
  Grades,
  Pathways,
}

@Component({
  components: {
    InterstitialDataCollection,
    ValidationProvider,
    ValidationObserver,
    GradeCollection,
    OrgRegionSelect,
    YourSchool,
  },
})
export default class LearningPathways extends FormBase {
  name = "";
  model: any = {
    FilterOption: FilterOption.None,
  };

  auth_service: AuthorizationService = AuthorizationService.getInstance();
  ssoApiService: SSOApiService = new SSOApiService();
  valid_interstitial_data = true;

  orgApiService: OrganizationApiService = new OrganizationApiService();
  grades_option: FilterOption = FilterOption.Grades;
  pathways_option: FilterOption = FilterOption.Pathways;

  get Form(): string {
    return "LearningPathways";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get GradeOptions(): any[] {
    if (
      !this.Metadata ||
      !this.Metadata[this.Locale] ||
      !this.Metadata[this.Locale].grades
    ) {
      return [];
    }

    const grades_ops = (this.Metadata[this.Locale].grades as any[]).map((q) => {
      return {
        text: q.text,
        value: q.text,
      };
    });

    return grades_ops;
  }

  get PathwayOptions(): any[] {
    if (
      !this.Metadata ||
      !this.Metadata[this.Locale] ||
      !this.Metadata[this.Locale].pathways
    ) {
      return [];
    }

    const pathway_ops = (this.Metadata[this.Locale].pathways as any[]).map(
      (q) => {
        return {
          text: q.text,
          value: q.text,
        };
      }
    );

    return pathway_ops;
  }

  get HasOptionsFiltered(): boolean {
    return this.model.FilterOption !== FilterOption.None;
  }

  mounted() {
    this.GetFormData().then(() => {
      if (!this.Editing) {
        this.ssoApiService.GetUserValidInterstitialData().then((resp) => {
          if (resp.Success) {
            this.valid_interstitial_data = resp.Value;
          }
        });

        /* this determines if a user will get the grade options or pathway options based on their subject id */
        let id_int = 0;
        for (let i = 0; i < 8; ++i) {
          id_int += this.auth_service.SubjectId.charCodeAt(i);
        }

        this.model.FilterOption =
          id_int % 2 === 0 ? FilterOption.Grades : FilterOption.Pathways;
      }
    });
  }

  onSubmit() {
    let gradeCollectionValid = false;
    if (this.$refs.gradeCollection !== undefined) {
      gradeCollectionValid = (
        this.$refs.gradeCollection as GradeCollection
      ).validate();
    } else {
      gradeCollectionValid = true;
    }

    if (gradeCollectionValid !== true) {
      return;
    }

    if (this.Editing) {
      this.SaveSubmission(this.model);
    } else {
      const submission_requests: CreateSubmissionRequest[] = [
        new CreateSubmissionRequest(this.Form, this.Instance, this.model),
      ];

      if (this.model.InterstitialData) {
        submission_requests.push(
          new CreateSubmissionRequest(
            "Canvas",
            null,
            this.model.InterstitialData
          )
        );
      }

      this.submissionApiService
        .CreateSubmissions(submission_requests)
        .then((resp) => {
          console.log(resp);
          if (resp.Success && resp.Value) {
            this.SubmissionComplete();
          }
        });
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}

textarea.binput {
  max-width: 100%;
}

.step-progress {
  display: inline;
}

hr {
  background-color: rgb(145, 145, 145);
}

.phelp {
  font-style: italic;
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
