<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h1 class="h2">{{ $t(`StemStoryTime.Header`) }}</h1>
      <p>
        {{ $t(`StemStoryTime.Intro.Text`) }}
      </p>

      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider
            v-slot="{ errors, valid }"
            name="meetings"
            rules="required"
          >
            <!-- meetings table -->
            <table id="meetings_table">
              <thead>
                <tr>
                  <th />
                  <th>{{ $t(`StemStoryTime.Labels.MeetingTopic`) }}</th>
                  <th>{{ $t(`StemStoryTime.Labels.MeetingStartTime`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(m, idx) in meetings" :key="idx">
                  <td class="select_col">
                    <!-- @vue-ignore -->
                    <b-form-checkbox :value="m.Id" v-model="model.Meetings" />
                  </td>
                  <td>{{ m.Topic }}</td>
                  <td>{{ m.StartTime }}</td>
                </tr>
              </tbody>
            </table>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t("StemStoryTime.Validation.MeetingRequired")
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- School / Organization Information -->
          <div class="mt-4">
            <your-school id="your_school" v-model="model.School" />
          </div>

          <!-- Participation Information  -->
          <div id="classes">
            <h2 class="h5">
              {{ $t(`ClassesOrGroups.Label`) }}
            </h2>
            <validation-provider
              v-slot="{ errors, valid }"
              name="classcollection"
              :rules="{ required: true, min: 1 }"
            >
              <class-collection
                ref="classCollection"
                id="classcollection"
                v-model="model.Classes"
                description-values
              />
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>

          <!-- Additional Information -->
          <div id="additional_info">
            <h2 class="h5">
              {{ $t(`StemStoryTime.Labels.AdditionalInformation`) }}
            </h2>

            <number-of-adults
              id="num_adults"
              v-model="model.NumAdults"
              allow-zero
              :label="$t(`StemStoryTime.Questions.NumAdults`)"
            />

            <role id="role" v-model="model.Role" />

            <learned-about-project
              id="learned_about"
              v-model="model.LearnedAbout"
            />

            <feedback id="feedback" v-model="model.Feedback" />

            <mailing-list id="mailing_list" v-model="model.MailingList" />
          </div>

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-2"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t(`StemStoryTime.Confirmation.Header`) }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t(`StemStoryTime.Confirmation.SubHeader`) }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t(`StemStoryTime.Confirmation.Body`)" />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min } from "vee-validate/dist/rules";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import ClassCollection from "@/components/ClassCollection.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import MailingList from "@/components/questions/MailingList.vue";
import TermsAndConditions from "@/components/questions/TermsAndConditions.vue";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import Feedback from "@/components/questions/Feedback.vue";
import Role from "@/components/questions/Role.vue";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

/*
metadata example
{
  "meetings": [
    {
      "Id": 123,
      "Topic": "Some Topic 1",
      "StartTime": "2025-01-01 10:30 am"
    },
    {
      "Id": 456,
      "Topic": "Some Topic 2",
      "StartTime": "2025-04-20 6:09 am"
    }
  ]
}
*/

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    YourSchool,
    ClassCollection,
    NumberOfAdults,
    LearnedAboutProject,
    MailingList,
    TermsAndConditions,
    YesNoRadioOptions,
    Feedback,
    Role,
  },
})
export default class StemStoryTime extends FormBase {
  model: any = {
    Meetings: [],
  };

  get Form(): string {
    return "StemStoryTime";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  mounted() {
    this.GetFormData();
  }

  get meetings(): any[] {
    return this.Metadata.meetings ?? [];
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>

<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table {
  width: 100%;
}

th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

.select_col {
  width: 25px;
}

thead {
  background-color: #0053a1;
  color: white;
}
</style>
