<template>
  <div :id="id">
    <!-- Removed for final UAT before initial go live, maybe bring it back maybe delete it -->
    <p class="phelp h5 font-italic">
      {{ $t("ClassesOrGroups.HelpText") }}
    </p>
    <div
      v-for="(cItem, cIdx) in classes"
      :key="cIdx"
      class="mb-4 mt-4 border border-light rounded p-4 thick-rounded"
    >
      <h5 style="font-weight: 800">
        {{ $t("ClassCollection.Class") }} {{ cIdx + 1 }}
      </h5>

      <b-button
        v-if="cIdx > 0"
        :id="`${id}_removeclassbtn_${cIdx}`"
        variant="danger"
        class="fullwidth ml-4 removeclassbtn"
        @click="onRemoveClassClicked(cIdx)"
      >
        {{ $t("ClassCollection.RemoveClass") }}
      </b-button>

      <div v-for="(gItem, gIdx) in classes[cIdx].Grades" :key="gIdx">
        <div class="form-row">
          <div class="col-lg mr-2 required-field">
            <label :for="`classcollection-grade-${gIdx}-${cIdx}`">{{
              $t("ClassCollection.Grade")
            }}</label>
            <validation-provider
              v-slot="{ errors, valid }"
              :name="`ClassGrade-${cIdx}-${gIdx}`"
              :rules="{
                required: true,
                unique_class_grade: { c_item: cItem },
              }"
            >
              <b-select
                placeholder="Grade"
                :id="`${id}-grade-${gIdx}-${cIdx}`"
                :options="gradeOptions"
                v-model="cItem.Grades[gIdx].Grade"
                @input="onInput"
                size="sm"
                ref="GradeInput"
                :state="errors[0] ? false : valid ? true : null"
              >
                <template #first>
                  <b-select-option :value="null" disabled
                    >-- {{ $t("ClassCollection.Grade") }} --</b-select-option
                  >
                </template>
              </b-select>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div class="col-lg mr-2 required-field">
            <label :for="`classcollection-numstudents-${gIdx}-${cIdx}`">{{
              $t("ClassCollection.NumberOfStudents")
            }}</label>
            <validation-provider
              v-slot="{ errors, valid }"
              :name="`NumClassGradeStudents-${cIdx}-${gIdx}`"
              :rules="{
                required: true,
                min_value_translated: 1,
              }"
            >
              <b-form-input
                :id="`${id}-numstudents-${gIdx}-${cIdx}`"
                type="number"
                v-model="cItem.Grades[gIdx].NumStudents"
                @input="onInput"
                size="sm"
                number
                ref="NumStudentInput"
                :state="errors[0] ? false : valid ? true : null"
              />
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div class="col d-flex">
            <b-button
              v-if="gIdx > 0"
              variant="danger"
              :id="`${id}_removegradebtn_${cIdx}_${gIdx}`"
              @click="onRemoveGradeClicked(cItem, gIdx)"
              class="align-self-center removegradebtn btn-lg"
            >
              {{ $t("ClassCollection.RemoveGrade") }}
            </b-button>
          </div>
        </div>
      </div>
      <b-button
        :id="`${id}_addgradebtn_${cIdx}`"
        class="addgradebtn btn-lg"
        variant="outline-primary"
        @click="onAddGradeClicked(cItem)"
      >
        {{ $t("ClassCollection.AddGrade") }}
      </b-button>

      <!-- post grade slot -->
      <slot name="class-append" :item="cItem" />
    </div>
    <b-button
      :id="`${id}_addclassbtn`"
      variant="outline-primary"
      class="btn-xl fullwidth addclassbtn"
      @click="onAddClassClicked"
    >
      {{ $t("ClassCollection.AddClass") }}
    </b-button>
  </div>
</template>

<script lang="ts">
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrgEnumResponse from "@/types/GetOrgEnumResponse";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { min_value } from "vee-validate/dist/rules";

extend("unique_class_grade", {
  validate(value, { c_item }: any) {
    const class_grades = c_item.Grades.map((g: any) => g.Grade);
    let g_c = 0;

    for (const g of class_grades.values()) {
      if (g === value) ++g_c;
    }

    if (g_c > 1) return "ClassCollection.Validation.DuplicateGrade";
    else return true;
  },
  params: ["c_item"],
});

extend("min_value_translated", {
  ...min_value,
  message: "ClassCollection.Validation.GreaterThan0",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ClassCollection extends Vue {
  @Prop(String) id!: string;
  @Prop(Array) value!: any[];
  @Prop(Boolean) descriptionValues: boolean | undefined;

  classes: any[] = [];
  gradeOptions: any[] = [];
  gradeModel: any = {};
  numStudentsValid: boolean | null = null;

  orgService: OrganizationApiService = new OrganizationApiService();

  get Locale(): string {
    return this.$i18n.locale;
  }

  @Watch("value")
  onValueChanged() {
    this.classes = this.value || [];
  }

  @Watch("Locale")
  onLocaleChanged() {
    this.getData();
  }

  mounted() {
    this.onValueChanged();

    if (this.classes.length === 0) {
      this.classes = [
        {
          Class: this.classes.length + 1,
          Grades: [
            {
              Grade: null,
              NumStudents: null,
            },
          ],
        },
      ];
    }

    this.getData();
  }

  getData() {
    const locale: string = this.Locale === "fr" ? "fr-CA" : "en-CA";

    this.orgService.GetEnumerationList("LTSAgeGroups", locale).then((resp) => {
      this.gradeOptions = [];
      resp.Value.forEach((el: GetOrgEnumResponse) => {
        this.gradeOptions.push({
          text: el.Text,
          value: this.descriptionValues === true ? el.Description : el.Id,
        });
      });
    });
  }

  onAddClassClicked() {
    this.classes.push({
      Class: this.classes.length + 1,
      Grades: [
        {
          Grade: null,
          NumStudents: null,
        },
      ],
    });

    this.onInput();
  }

  onRemoveClassClicked(idx: number) {
    this.classes.splice(idx, 1);
    this.onInput();
  }

  onAddGradeClicked(cItem: any) {
    cItem.Grades.push({
      Grade: null,
      NumStudents: null,
    });
    this.onInput();
  }

  onRemoveGradeClicked(cItem: any, gIdx: number) {
    cItem.Grades.splice(gIdx, 1);
    this.onInput();
  }

  onInput() {
    this.$emit("input", this.classes);
  }

  validateInputs(): boolean {
    let inputs: any = this.$refs.NumStudentInput;
    inputs = inputs.concat(this.$refs.GradeInput);

    let ret = true;
    inputs.forEach((i: any) => {
      if (i.state === false) {
        ret = false;
      }
    });

    return ret;
  }

  validateClasses(): boolean {
    if (!this.classes || this.classes.length < 1) {
      return false;
    }

    let ret = true;
    this.classes.forEach((c) => {
      if (!c.Grades || c.Grades.length < 1) ret = false;
    });

    return ret;
  }

  validate(): boolean {
    const vc = this.validateClasses();
    const vi = this.validateInputs();
    return vc === true && vi === true;
  }
}
</script>
<style scoped>
.removeclassbtn {
  margin-bottom: 3px;
  margin-left: 5px;
}

.removegradebtn {
  margin-bottom: 3px;
}

.addgradebtn {
  margin-bottom: 3px;
  margin-top: 10px;
}

.addclassbtn {
  margin-bottom: 3px;
}

ul.dropdown-menu.show {
  width: 100%;
}

.thick-rounded {
  border-width: 3px !important;
  border-radius: 5px !important;
}

.removegradebtn {
  position: absolute;
  bottom: 0;
  height: 50px;
}
</style>
