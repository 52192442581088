<template>
  <b-form-group
    id="prov-group"
    :label="$t('ProvinceTerritory')"
    label-for="province"
  >
    <b-form-select
      id="province"
      :options="provinceOptions"
      v-model="province"
      @input="onInput"
    />
  </b-form-group>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class ProvinceSelect extends Vue {
  @Prop(String) id!: string;
  @Prop(String) value!: string;

  province: string = this.value || "";

  @Watch("value")
  onValueChanged() {
    this.province = this.value || "";
  }

  mounted() {
    this.onValueChanged();
  }

  provinceOptions = [
    { text: "Yukon Territory", value: "YT" },
    { text: "Quebec", value: "QC" },
    { text: "Alberta", value: "AB" },
    { text: "Nunavut", value: "NU" },
    { text: "Manitoba", value: "MB" },
    { text: "Saskatchewan", value: "SK" },
    { text: "British Columbia", value: "BC" },
    { text: "Nova Scotia", value: "NS" },
    { text: "Northwest Territories", value: "NT" },
    { text: "Ontario", value: "ON" },
    { text: "New Brunswick", value: "NB" },
    { text: "Newfoundland and Labrador", value: "NL" },
    { text: "Prince Edward Island", value: "PE" },
    { text: "Colombie-Britannique", value: "BC" },
    { text: "Nouveau-Brunswick", value: "NB" },
    { text: "Terre-Neuve-et-Labrador", value: "NL" },
    { text: "Nouvelle-Écosse", value: "NS" },
    { text: "Territoires du Nord-Ouest", value: "NT" },
    { text: "Île-du-Prince-Édouard", value: "PE" },
    { text: "Québec", value: "QC" },
    { text: "Territoire du Yukon", value: "YT" },
  ];

  onInput() {
    this.$emit("input", this.province);
  }
}
</script>
