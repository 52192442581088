<template>
  <b-form-group
    :id="id"
    :label="$t('SchoolLookup.Label')"
    :label-for="id"
    class="required-field"
  >
    <b-input-group class="mb-2">
      <b-input
        :id="id"
        type="text"
        v-model="searchText"
        @keydown.enter="onItemSelected"
        @keydown.down="onDown"
        @keydown.up="onUp"
        @input="onSearchInput"
        :disabled="disabled"
        autocomplete="off"
      />
      <b-input-group-append>
        <b-btn
          :id="`${id}_btn_clear`"
          @click="onClearClicked"
          :disabled="disabled"
          >{{ $t("SchoolLookup.Clear") }}</b-btn
        >
      </b-input-group-append>
    </b-input-group>

    <b-list-group>
      <b-list-group-item
        v-for="(item, idx) in displayItems"
        :key="idx"
        :id="`${id}_org_item_${idx}`"
        @click="onItemSelected"
        @mouseenter="currentIdx = idx"
        :class="idx === currentIdx ? 'active' : ''"
      >
        {{ item.text }}
      </b-list-group-item>
    </b-list-group>
  </b-form-group>
</template>

<script lang="ts">
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrganizationResponse from "@/types/GetOrganizationResponse";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class SchoolLookup extends Vue {
  @Prop(String) id!: string;
  @Prop(String) label!: string;
  @Prop(Object) value!: GetOrganizationResponse;
  @Prop(String) regionfilter!: string;
  @Prop(Boolean) disabled!: boolean;

  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;
  displayItems: any[] = [];
  selected: GetOrganizationResponse | null = this.value || null;

  orgService: OrganizationApiService = new OrganizationApiService();
  currentIdx = 0;
  searchText = "";

  @Watch("value")
  onValueChanged(val: any) {
    if (val === null) {
      this.onClearClicked();
    }

    this.selected = this.value || null;

    if (this.selected && this.selected.Name) {
      this.searchText = `${this.selected.Name} - ${this.selected.City}`;
    }
  }

  mounted() {
    this.onValueChanged(this.value);
  }

  onUp() {
    if (this.currentIdx > 0) this.currentIdx--;
  }

  onDown() {
    if (this.currentIdx < this.displayItems.length - 1) this.currentIdx++;
  }

  onSearchInput() {
    this.currentIdx = 0;
    if (this.timeout) clearTimeout(this.timeout);
    if (this.searchText.length > 2) {
      this.timeout = setTimeout(this.getData, this.typeTimer);
    }
  }

  onItemSelected() {
    this.selected = this.displayItems[this.currentIdx].value;
    this.searchText = this.displayItems[this.currentIdx].text;
    this.displayItems = [];
    this.onInput();
  }

  onClearClicked() {
    this.Clear();
  }

  Clear() {
    this.searchText = "";
    this.displayItems = [];
    this.currentIdx = 0;
    this.selected = null;
    this.onInput();
  }

  onInput() {
    this.$emit("input", this.selected);
  }

  getData() {
    this.orgService.GetOrganizations(this.searchText, "").then((resp) => {
      this.displayItems = [];
      const regions = this.regionfilter
        ? resp.Value.filter((x) => x.RegionId === this.regionfilter)
        : resp.Value;

      regions.forEach((element) => {
        this.displayItems.push({
          text: `${element.Name} - ${element.City}`,
          value: element,
        });
      });
    });
  }
}
</script>
