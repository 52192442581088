import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import Form from "@/types/Entities/Form";

export default class FormApiService extends BaseApiService {
  async GetForms(): Promise<UseCaseResponse<Form[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Form`)
      .then((resp) => resp.data as UseCaseResponse<Form[]>);
  }

  async GetFormById(id: string) {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Form/${id}`)
      .then((resp) => resp.data as UseCaseResponse<object>);
  }

  async CreateForm(form: object): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/registration/Form`, form)
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async UpdateForm(form: any): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/registration/Form/${form.Id}`, form)
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async GetFormSubmissions(formId: string) {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Form/${formId}/Submissions`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }

  async CreateFormInstance(instance: any): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(
        `${this.url}/api/registration/Form/${instance.FormId}/FormInstances`,
        instance
      )
      .then((resp) => resp.data as UseCaseResponse<string>);
  }

  async GetFormInstancesByFormId(
    formId: string
  ): Promise<UseCaseResponse<object[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/registration/Form/${formId}/FormInstances`)
      .then((resp) => resp.data as UseCaseResponse<object[]>);
  }

  async UpdateFormInstance(instance: any): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(
        `${this.url}/api/registration/Form/${instance.FormId}/FormInstances/${instance.Id}`,
        instance
      )
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteFormInstance(
    formId: string,
    instanceId: string
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(
        `${this.url}/api/registration/Form/${formId}/FormInstances/${instanceId}`
      )
      .then((resp) => resp.data as UseCaseResponse<boolean>);
  }
}
