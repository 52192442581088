<template>
  <validation-provider
    v-slot="{ errors, valid }"
    name="PostalCode"
    :rules="{
      required: true,
      postalCode:
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]\d[ABCEGHJ-NPRSTV-Z]\d$/,
    }"
  >
    <b-form-group
      id="postal-group"
      :label="label || $t('PostalCode')"
      label-for="postal"
    >
      <b-input id="postal" type="text" v-model="postal" @input="onInput" />
    </b-form-group>
    <b-form-invalid-feedback :state="valid">
      <span v-for="(error, index) in errors" :key="index">{{ $t(error) }}</span>
    </b-form-invalid-feedback>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { extend, ValidationProvider } from "vee-validate";
import { regex as postalCode } from "vee-validate/dist/rules";

/* original regex:
 /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, */

extend("postalCode", {
  ...postalCode,
  message: "Validation.PostalCodeFormat",
});

@Component({
  components: {
    ValidationProvider,
  },
})
export default class PostalCodeInput extends Vue {
  @Prop(String) id!: string;
  @Prop(String) value!: string;
  @Prop(String) label!: string;

  postal: string = this.value || "";

  @Watch("value")
  onValueChanged() {
    this.postal = this.value || "";
  }

  mounted() {
    this.onValueChanged();
  }

  onInput() {
    this.$emit("input", this.postal);
  }
}
</script>
