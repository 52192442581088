export default interface RouteData {
  FormId: string | null;
  RequireInstance: boolean;
  PreventMultipleSubmissions: boolean;
  InstanceId: string | null;
  InstanceStart: string | null;
  InstanceEnd: string | null;
  InstanceActive: boolean;
  Submissions: number;
  FormMetadata: any;
}

export function NewRouteData(): RouteData {
  return {
    FormId: null,
    RequireInstance: false,
    PreventMultipleSubmissions: false,
    InstanceId: null,
    InstanceStart: null,
    InstanceEnd: null,
    InstanceActive: true,
    Submissions: 0,
    FormMetadata: {},
  };
}
