<template>
  <div :id="id">
    <p class="question required-field" :id="`${id}-header`">
      {{ $t("Roles.Header") }}
    </p>

    <validation-provider
      v-slot="{ errors, valid }"
      :name="`${id}-vp-name`"
      :rules="{ required: true }"
    >
      <b-form-group
        :id="`${id}-group`"
        :label="Label"
        :label-for="`${id}-select`"
        class="required-field"
      >
        <b-form-select
          :id="`${id}-select`"
          class="binput"
          v-model="role"
          :options="roleOptions"
          :state="errors[0] ? false : valid ? true : null"
          @input="onInput"
        />
      </b-form-group>
      <b-form-invalid-feedback :state="valid">
        <span v-for="(error, index) in errors" :key="index">{{
          $t(error)
        }}</span>
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Role extends Vue {
  @Prop(String) id!: string;
  @Prop(String) value!: string;
  @Prop(String) label!: string;

  role: string | null = this.value || null;

  get Label(): string {
    return this.label || this.$t("Roles.SubHeader").toString();
  }

  get roleOptions(): Array<any> {
    return [
      {
        text: this.$root.$t("Roles.Options.Educator"),
        value: "Educator",
      },
      {
        text: this.$root.$t("Roles.Options.OtherEducator"),
        value: "OtherEducator",
      },
      {
        text: this.$root.$t("Roles.Options.Student"),
        value: "Student",
      },
      {
        text: this.$root.$t("Roles.Options.Parent"),
        value: "Parent",
      },
      {
        text: this.$root.$t("Roles.Options.Volunteer"),
        value: "Volunteer",
      },
      {
        text: this.$root.$t("Roles.Options.Donor"),
        value: "Donor",
      },
      {
        text: this.$root.$t("Roles.Options.Staff"),
        value: "Staff",
      },
      {
        text: this.$root.$t("Roles.Options.Other"),
        value: "Other",
      },
    ];
  }

  @Watch("value")
  onValueChanged() {
    this.role = this.value;
  }

  mounted() {
    this.onValueChanged();
  }

  onInput() {
    this.$emit("input", this.role);
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
  font-weight: bold;
}
</style>
