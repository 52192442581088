var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":_vm.id}},[_c('p',{staticClass:"phelp h5 font-italic"},[_vm._v(" "+_vm._s(_vm.$t("ClassesOrGroups.HelpText"))+" ")]),_vm._l((_vm.classes),function(cItem,cIdx){return _c('div',{key:cIdx,staticClass:"mb-4 mt-4 border border-light rounded p-4 thick-rounded"},[_c('h5',{staticStyle:{"font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.$t("ClassCollection.Class"))+" "+_vm._s(cIdx + 1)+" ")]),(cIdx > 0)?_c('b-button',{staticClass:"fullwidth ml-4 removeclassbtn",attrs:{"id":`${_vm.id}_removeclassbtn_${cIdx}`,"variant":"danger"},on:{"click":function($event){return _vm.onRemoveClassClicked(cIdx)}}},[_vm._v(" "+_vm._s(_vm.$t("ClassCollection.RemoveClass"))+" ")]):_vm._e(),_vm._l((_vm.classes[cIdx].Grades),function(gItem,gIdx){return _c('div',{key:gIdx},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg mr-2 required-field"},[_c('label',{attrs:{"for":`classcollection-grade-${gIdx}-${cIdx}`}},[_vm._v(_vm._s(_vm.$t("ClassCollection.Grade")))]),_c('validation-provider',{attrs:{"name":`ClassGrade-${cIdx}-${gIdx}`,"rules":{
              required: true,
              unique_class_grade: { c_item: cItem },
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-select',{ref:"GradeInput",refInFor:true,attrs:{"placeholder":"Grade","id":`${_vm.id}-grade-${gIdx}-${cIdx}`,"options":_vm.gradeOptions,"size":"sm","state":errors[0] ? false : valid ? true : null},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- "+_vm._s(_vm.$t("ClassCollection.Grade"))+" --")])]},proxy:true}],null,true),model:{value:(cItem.Grades[gIdx].Grade),callback:function ($$v) {_vm.$set(cItem.Grades[gIdx], "Grade", $$v)},expression:"cItem.Grades[gIdx].Grade"}}),_c('b-form-invalid-feedback',{attrs:{"state":valid}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(_vm.$t(error)))])}),0)]}}],null,true)})],1),_c('div',{staticClass:"col-lg mr-2 required-field"},[_c('label',{attrs:{"for":`classcollection-numstudents-${gIdx}-${cIdx}`}},[_vm._v(_vm._s(_vm.$t("ClassCollection.NumberOfStudents")))]),_c('validation-provider',{attrs:{"name":`NumClassGradeStudents-${cIdx}-${gIdx}`,"rules":{
              required: true,
              min_value_translated: 1,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-form-input',{ref:"NumStudentInput",refInFor:true,attrs:{"id":`${_vm.id}-numstudents-${gIdx}-${cIdx}`,"type":"number","size":"sm","number":"","state":errors[0] ? false : valid ? true : null},on:{"input":_vm.onInput},model:{value:(cItem.Grades[gIdx].NumStudents),callback:function ($$v) {_vm.$set(cItem.Grades[gIdx], "NumStudents", $$v)},expression:"cItem.Grades[gIdx].NumStudents"}}),_c('b-form-invalid-feedback',{attrs:{"state":valid}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(_vm.$t(error)))])}),0)]}}],null,true)})],1),_c('div',{staticClass:"col d-flex"},[(gIdx > 0)?_c('b-button',{staticClass:"align-self-center removegradebtn btn-lg",attrs:{"variant":"danger","id":`${_vm.id}_removegradebtn_${cIdx}_${gIdx}`},on:{"click":function($event){return _vm.onRemoveGradeClicked(cItem, gIdx)}}},[_vm._v(" "+_vm._s(_vm.$t("ClassCollection.RemoveGrade"))+" ")]):_vm._e()],1)])])}),_c('b-button',{staticClass:"addgradebtn btn-lg",attrs:{"id":`${_vm.id}_addgradebtn_${cIdx}`,"variant":"outline-primary"},on:{"click":function($event){return _vm.onAddGradeClicked(cItem)}}},[_vm._v(" "+_vm._s(_vm.$t("ClassCollection.AddGrade"))+" ")]),_vm._t("class-append",null,{"item":cItem})],2)}),_c('b-button',{staticClass:"btn-xl fullwidth addclassbtn",attrs:{"id":`${_vm.id}_addclassbtn`,"variant":"outline-primary"},on:{"click":_vm.onAddClassClicked}},[_vm._v(" "+_vm._s(_vm.$t("ClassCollection.AddClass"))+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }