<template>
  <b-container fluid="xl">
    <b-input-group
      id="forms-group"
      prepend="Form"
      class="mt-3"
      label-for="forms"
    >
      <b-form-select
        id="forms"
        class="binput"
        :options="formOptions"
        v-model="query.FormId"
        @input="onQueryChanged"
      />
      <b-input-group-append>
        <b-button @click="onClearFormClicked">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group
      id="instances-group"
      prepend="Instance"
      class="mt-3"
      label-for="instances"
    >
      <b-form-select
        id="instances"
        class="binput"
        :options="Instances"
        v-model="query.InstanceId"
        @input="onQueryChanged"
      />
      <b-input-group-append>
        <b-button @click="query.InstanceId = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group
      id="email-group"
      prepend="Email"
      class="mt-3"
      label-for="email"
    >
      <b-input
        id="email"
        class="binput"
        v-model="query.Search"
        @input="onEmailInput"
      />
    </b-input-group>
    <div>
      <b-table
        id="submissions-table"
        ref="subtbl"
        responsive
        striped
        hover
        :fields="fields"
        :items="querySubmissions"
        :per-page="items_per_page"
        :current-page="current_page"
      >
        <template #cell(CreatedOn)="data">
          {{ new Date(data.item.CreatedOn).toLocaleString() }}
        </template>
        <template #cell(Actions)="data">
          <b-dropdown text="Actions">
            <b-dropdown-item
              @click="onViewClicked(data.item.RegistrationJson)"
              v-b-modal.modal-reg
              >View</b-dropdown-item
            >
            <b-dropdown-item @click="onEditClicked(data.item)"
              >Edit</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="current_page"
        :total-rows="total_rows"
        :per-page="items_per_page"
        align="right"
        aria-controls="submissions-table"
      />
    </div>
    <b-modal
      id="modal-reg"
      title="Registration Json"
      scrollable
      centered
      size="xl"
      ok-only
    >
      <pre class="my-4">
        {{ registration_string }}
      </pre>
    </b-modal>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FormApiService from "@/services/FormApiService";
import AuthorizationService from "@/services/AuthorizationService";
import SubmissionApiService from "@/services/SubmissionApiService";
import QuerySubmissionsRequest from "@/types/pocos/QuerySubmissionsRequest";
import Form from "@/types/Entities/Form";
import FormInstance from "@/types/Entities/FormInstance";
import Submission from "@/types/Entities/Submission";
import { RouteRecordPublic } from "vue-router";

@Component
export default class SubmissionList extends Vue {
  name = "";
  model: any = {};
  forms: Array<Form> = [];
  form_instances: Array<FormInstance> = [];
  registration_string = "";

  current_page = 1;
  total_rows = 0;
  items_per_page = 10;
  query: QuerySubmissionsRequest = new QuerySubmissionsRequest();

  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;

  authService: AuthorizationService = AuthorizationService.getInstance();
  formApiService: FormApiService = new FormApiService();
  submissionApiService: SubmissionApiService = new SubmissionApiService();

  routes: RouteRecordPublic[] = [];

  fields: object[] = [
    {
      key: "FormName",
      label: "Form",
    },
    {
      key: "FormInstanceName",
      label: "Instance",
    },
    {
      key: "FormVersion",
      label: "Form Version",
    },
    {
      key: "UserEmail",
      label: "User",
    },
    {
      key: "CreatedOn",
      label: "Created",
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  get formOptions() {
    return this.forms.map((f) => {
      return {
        text: f.Name,
        value: f.Id,
      };
    });
  }

  get Instances(): object[] {
    const form = this.forms.find((f) => f.Id === this.query.FormId);
    if (!form || !form.FormInstances || form.FormInstances.length === 0) {
      return [];
    } else {
      return form.FormInstances.map((fi) => {
        return {
          text: fi.Name,
          value: fi.Id,
        };
      });
    }
  }

  mounted() {
    this.routes = this.$router.getRoutes();

    const session_query: string | null = sessionStorage.getItem(
      "CR_Submissions_Query"
    );
    if (session_query) {
      this.query = JSON.parse(session_query);
    }

    this.formApiService.GetForms().then((resp) => {
      if (resp.Success) {
        this.forms = resp.Value.sort((a, b) => {
          if (a.Name > b.Name) return 1;
          else if (b.Name > a.Name) return -1;
          else return 0;
        });
        if (this.forms.length > 0) {
          this.form_instances = this.forms.flatMap(
            (f) => f.FormInstances
          ) as FormInstance[];
        }
      }
    });
  }

  onEmailInput() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.onQueryChanged();
    }, this.typeTimer);
  }

  onClearFormClicked() {
    this.query.FormId = "";
    this.query.InstanceId = "";
  }

  onEditClicked(reg: Submission) {
    const form = this.forms.find((f) => f.Id === reg.FormId);
    const route = this.routes.find((r) => r.name === form?.Name);
    if (!form || !route) {
      return;
    }

    const instance = form.FormInstances?.find(
      (i) => i.Id === reg.FormInstanceId
    );

    const path = route.path.replace(":instance?", instance?.Name ?? "");
    this.$router.push(`${path}?id=${reg.Id}`);
  }

  onQueryChanged() {
    sessionStorage.setItem("CR_Submissions_Query", JSON.stringify(this.query));

    if (this.$refs.subtbl) {
      (this.$refs.subtbl as any).refresh();
    }
  }

  querySubmissions(ctx: any, callback: any) {
    this.query.Skip = (this.current_page - 1) * this.items_per_page;
    this.query.Take = this.items_per_page;

    this.submissionApiService
      .QuerySubmissions(this.query)
      .then((resp) => {
        if (resp.Success) {
          this.total_rows = resp.TotalRecords;
          callback(resp.Value);
        }
      })
      .catch(() => callback([]));

    return null;
  }

  onViewClicked(reg: string) {
    reg = JSON.parse(reg);
    this.registration_string = JSON.stringify(reg, null, 2);
  }
}
</script>
