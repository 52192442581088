<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h3>{{ $t("Canvas.Header") }}</h3>

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <interstitial-data-collection v-model="model" />
          <div style="margin-top: 10px">
            <b-button
              id="btn_submit"
              type="submit"
              variant="primary"
              class="mt-2"
              :disabled="saving"
              >{{ $t("Submit") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>
    <div v-else>Redirecting...</div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import GradeCollection from "@/components/GradeCollection.vue";
import ConfigurationService from "@/services/ConfigurationService";
import OrganizationApiService from "@/services/OrganizationApiService";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import FormBase from "@/types/FormBase";
import YourSchool from "@/components/questions/YourSchool.vue";
import InterstitialDataCollection from "@/components/InterstitialDataCollection.vue";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

@Component({
  components: {
    InterstitialDataCollection,
    ValidationProvider,
    ValidationObserver,
    GradeCollection,
    OrgRegionSelect,
    YourSchool,
  },
})
export default class Canvas extends FormBase {
  name = "";

  orgApiService: OrganizationApiService = new OrganizationApiService();
  returnUrl: string | null = null;

  configService: ConfigurationService = ConfigurationService.getInstance();

  get Form(): string {
    return "Canvas";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get IdServerUrl(): string {
    return this.configService.getUrl("ltsol_id");
  }

  get roleOptions() {
    return [
      { text: this.$root.$t("Canvas.Roles.Volunteer"), value: "Volunteer" },
      { text: this.$root.$t("Canvas.Roles.Educator"), value: "Educator" },
      { text: this.$root.$t("Canvas.Roles.Other"), value: "Other" },
    ];
  }

  get countryOptions() {
    return [
      { text: "Canada", value: "Canada" },
      { text: "United States", value: "United States" },
      { text: this.$root.$t("Canvas.Countries.Other"), value: "Other" },
    ];
  }

  get NotVolunteer(): boolean {
    return (
      this.model &&
      this.model.Roles &&
      this.model.Roles.findIndex((x: string) => x !== "Volunteer") >= 0
    );
  }

  mounted() {
    if (this.$route.query.returnurl) {
      this.returnUrl = this.$route.query.returnurl.toString();
    }

    this.GetFormData();
  }

  onSubmit() {
    let gradeCollectionValid = false;
    if (this.$refs.gradeCollection !== undefined) {
      gradeCollectionValid = (
        this.$refs.gradeCollection as GradeCollection
      ).validate();
    } else {
      gradeCollectionValid = true;
    }

    if (gradeCollectionValid === true) {
      this.SaveSubmission(this.model).then(() => {
        if (this.submissionComplete) {
          this.doRedirect();
        }
      });
    }
  }

  doRedirect() {
    //reroute
    if (this.returnUrl !== null) {
      const url = `${
        this.IdServerUrl
      }/Account/RedirectWithCode?returnurl=${encodeURIComponent(
        this.returnUrl
      )}`;
      window.location = url as any;
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

.step-progress {
  display: inline;
}

hr {
  background-color: rgb(145, 145, 145);
}

.phelp {
  font-style: italic;
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
